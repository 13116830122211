<template>
    <v-navigation-drawer hide-overlay style="height:100vh" width="400px" v-model="$store.state.bmiWindow" fixed temporary right>
        <v-list-item>
            <div class="text-h4 ma-6">Версия для слабовидящих</div>
        </v-list-item>

        <v-divider></v-divider>
        <v-list class="ma-3">
            <div class="text-body-1 mt-6 ml-4">Размер шрифта</div>
            <v-list-item>

                <v-btn-toggle v-model="$store.state.bmi.size" mandatory>
                    <v-btn>
                        <v-icon>mdi-format-align-left</v-icon>
                    </v-btn>
                    <v-btn>
                        <v-icon>mdi-format-align-center</v-icon>
                    </v-btn>
                    <v-btn>
                        <v-icon>mdi-format-align-right</v-icon>
                    </v-btn>
                </v-btn-toggle>
            </v-list-item>
            <div class="text-body-1 mt-6 ml-4">Инверсия цвета</div>
            <v-list-item>

                <v-btn-toggle v-model="$store.state.bmi.invert" mandatory>
                    <v-btn>
                        <v-icon>mdi-invert-colors-off</v-icon>
                    </v-btn>
                    <v-btn>
                        <v-icon>mdi-invert-colors</v-icon>
                    </v-btn>
                </v-btn-toggle>

            </v-list-item>
            <div class="text-body-1 mt-6 ml-4">Черно-белые цвета</div>
            <v-list-item>
                <v-btn-toggle v-model="$store.state.bmi.grayscale" mandatory>
                    <v-btn>
                        <v-icon>mdi-palette</v-icon>
                    </v-btn>
                    <v-btn>
                        <v-icon>mdi-image-filter-black-white</v-icon>
                    </v-btn>
                </v-btn-toggle>

            </v-list-item>

            <div class="text-body-1 mt-6 ml-4">Фоновая анимация</div>
            <v-list-item>
                <v-btn-toggle v-model="$store.state.bmi.bgOff" mandatory>
                    <v-btn>
                        <v-icon>mdi-eye-outline</v-icon>
                    </v-btn>
                    <v-btn>
                        <v-icon>mdi-eye-off-outline</v-icon>
                    </v-btn>
                </v-btn-toggle>

            </v-list-item>
            <v-list-item class="mt-6">

                <v-btn @click="reset()" elevation="1">
                    Сбросить настройки
                </v-btn>
            </v-list-item>

        </v-list>

    </v-navigation-drawer>
</template>
<script>
export default {
    props: {
        drawer: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        reset() {
            this.$store.state.bmi = {
                size: 0,
                invert: 0,
                grayscale: 0,
                bgOff: 0
            }
        }
    }
}
</script>