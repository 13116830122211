<template>
    <v-app :class="bmiClasses">
        <bmi />
        <div v-if="$store.state.bmi.bgOff !== 1">
            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>
        </div>
      <v-alert class="mt-10" color="red" dark v-if="$http.defaults.baseURL == 'https://psapi.expertspk.ru/v1'">
        Введутся технические работы по улучшению работы сервиса, данные не сохраняются
      </v-alert>
        <router-view />

        <v-footer padless fixed height="42" style="background-color: rgb(54, 93, 132);">
            <v-col class="text-center d-flex justify-space-between py-0 white--text align-center" cols="12">
                <div></div>
                <div>{{ new Date().getFullYear() }} — <strong>Акционерное общество «Национальные квалификации»</strong>
                </div>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn dark v-bind="attrs" v-on="on" @click="$store.state.bmiWindow = true"
                            icon><v-icon >mdi-eye</v-icon></v-btn>
                    </template>
                    <span class="text-h6">Версия для слабовидящих</span>
                </v-tooltip>
            </v-col>
        </v-footer>
    </v-app>
</template>

<script>
import bmi from "@/components/bmi.vue";

export default {
    components: {
        bmi
    },
  computed: {
        bmiClasses() {
            let classes = []
            switch (this.$store.state.bmi.size) {
                case 1: {
                    classes.push('bmi-size-1')
                    break;
                }
                case 2: {
                    classes.push('bmi-size-2')
                    break;
                }
            }
            if (this.$store.state.bmi.invert == 1) {
                classes.push('bmi-invert')
            }
            if (this.$store.state.bmi.grayscale == 1) {
                classes.push('bmi-grayscale')
            }
            if (this.$store.state.bmi.bgOff == 1) {
                classes.push('bmi-bg-off')
            }
            return classes
        }

    },
    data() {
        return {

        }
    }
}
</script>
<style>
.h-100{
  height: 100% !important;
}
.w-100{
  width: 100% !important;
}
.bmi-bg-off {
    background-color: black !important;

}

.bmi-size-1 {
    zoom: 1.1
}

.bmi-size-2 {
    zoom: 1.2
}

.bmi-invert {
    filter: invert(1)
}

.bmi-grayscale {
    filter: grayscale(1)
}

.theme--light.v-application {
    background: transparent;
}

.v-dialog {
    border-radius: 20px !important;
}

body {
    margin: auto;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    overflow: auto;
    background: linear-gradient(315deg, rgb(199 159 196 / 37%) 3%, rgba(60, 132, 206, 1) 38%, rgb(188 242 238) 50%, rgb(121 144 219 / 33%) 98%);
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    background-attachment: fixed;
}

@keyframes gradient {
    0% {
        background-position: 0% 0%;
    }

    50% {
        background-position: 100% 100%;
    }

    100% {
        background-position: 0% 0%;
    }
}

.wave {
    background: rgb(255 255 255 / 25%);
    border-radius: 1000% 1000% 0 0;
    position: fixed;
    width: 200%;
    height: 12em;
    animation: wave 10s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.8;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.wave:nth-of-type(2) {
    bottom: -1.25em;
    animation: wave 18s linear reverse infinite;
    opacity: 0.8;
}

.wave:nth-of-type(3) {
    bottom: -2.5em;
    animation: wave 20s -1s reverse infinite;
    opacity: 0.9;
}

@keyframes wave {
    2% {
        transform: translateX(1);
    }

    25% {
        transform: translateX(-25%);
    }

    50% {
        transform: translateX(-50%);
    }

    75% {
        transform: translateX(-25%);
    }

    100% {
        transform: translateX(1);
    }
}
</style>
