import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Sidebar_drawer:null,
    securityModal:false,
    settingsModal:false,
    bmiWindow:false,
    bmi:{
      size:0,
      invert:0,
      grayscale:0,
      bgOff:0
    }
  },
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_securityModal(state, payload) {
      state.securityModal = payload;
    },
    SET_settingsModal(state, payload) {
      state.settingsModal = payload;
    },
  },
  actions: {

  },
  getters: {

  },
});
