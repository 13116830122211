let config
if (process.env.NODE_ENV == 'development') {
  config={
    url: 'http://10.0.1.205:3000/oauth',

    params: {
      clientId: 'b87e4392-6484-4f7f-a144-1adbced9c0e2',
      scopes: [
        'phone',
        'name',
        'surname',
        'patronymic',
        'email',
        'avatar',
        'birthday',
        'rfCitizen',
        'inn',
        'gender',
        'postAddress',
        'regionCode',
        'snils',
        'city',],
    }
  }
} else {
  config={
    url: 'https://id.ao-nk.ru/oauth',

    params: {
      clientId: '20ec4da9-d497-48bc-ae19-cb7b2dbc8402',
      scopes: [
        'phone',
        'name',
        'surname',
        'patronymic',
        'email',
        'avatar',
        'birthday',
        'rfCitizen',
        'inn',
        'gender',
        'postAddress',
        'regionCode',
        'snils',
        'city',],
    }
  }
}

export default config
