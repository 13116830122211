import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
       // meta: { auth: false },
        path: '/login',
        component: () => import(/* webpackChunkName: "about" */ '../views/Auth/LoginView.vue'),
        
    },
      {
        path: "/restore",
        name: "restoreRequestView",
        component: () => import('@/views/Auth/restoreRequest.vue'),
      },
      {
        path: "/restore/:userId/:token",
        name: "restoreView",
        component: () => import('@/views/Auth/restore.vue'),
      },
      {
        path: "/confirm/:userId/:token",
        name: "confirmView",
        component: () => import('@/views/Auth/confirm.vue'),
      },
        {
        path: "/",
        redirect:  "index",
        component: () => import("@/layouts/full-layout/Layout"),
        children: [

            {
                meta: { auth: true },
                path: '/',
                component: () => import(/* webpackChunkName: "about" */ '../views/Profstandarts/profStandList.vue')
            },
            {
                meta: { auth: true },
                path: '/qualifications',
                component: () => import(/* webpackChunkName: "about" */ '../views/qualifications/qualificationsList.vue')
            },
            {
                meta: { auth: true },
                path: '/prof-standarts/view/:id',
                component: () => import(/* webpackChunkName: "about" */ '../views/Profstandarts/profStandView.vue')
            },
            {
                meta: { auth: true },
                path: '/classificators',
                component: () => import(/* webpackChunkName: "about" */ '../views/classificators/classificatorsList.vue')
            },
            {
                meta: { auth: true },
                path: '/tasks',
                component: () => import(/* webpackChunkName: "about" */ '../views/TaskPlan/TaskPlanView.vue')
            },
            {
                meta: { auth: true },
                path: '/users',
                component: () => import(/* webpackChunkName: "about" */ '../views/Users/users.vue')
            },
            {
                meta: { auth: true },
                path: '/qualifications/view/:id',
                component: () => import(/* webpackChunkName: "about" */ '../views/qualifications/qualificationView.vue')
            },
            {
                meta: { auth: true },
                path: '/evaluation-tools',
                component: () => import(/* webpackChunkName: "about" */ '../views/evaluationTools/evaluationToolsList.vue')
            },
            {
                meta: { auth: true },
                path: '/councils',
                component: () => import(/* webpackChunkName: "about" */ '../views/councils/councilMainView.vue')
            },
            {
                meta: { auth: true },
                path: '/evaluation-tools/view/:id',
                component: () => import(/* webpackChunkName: "about" */ '../views/evaluationTools/evaluationToolsView.vue'),
            }
        ]
    },
    {
      path: "/:pathMatch(.*)*",
      name: "404",
      component: () => import('@/views/404.vue'),
    }


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
